import React from "react";
import BlogPic from "../images/Blog1.png";
import Blog from "../pages/Blog";

const Blog1 = () => {
  return (
    <>
      <div className="max-w-screen-lg m-auto px-4 sm:px-12">
        <div className="py-8   " name="demo">
          <div className="max-w-screen-lg px-0 sm: m-auto">
            <p className="text-[#0A202B)] text-center text-base leading-6 font-semibold tracking-wide uppercase font-inter pt-8 ">
              ARTICLE
            </p>
            <h1 className="text-center text-4xl sm:text-5xl leading-10 font-extrabold font-inter text-[#111827] mt-4">
              Boosting Customer Engagement with Digital Assistants: A Recipe for
              Success
            </h1>
            <p className="text-lg leading-7 font-normal font-inter text-gray-500 text-left  mt-5 mb-4 sm:mb-16 ">
              The rise of digital platforms and online ordering has redefined
              the restaurant industry. Today, a strong digital presence isn't
              merely an option; it's a vital part of success. Digital assistants
              have emerged as key players in this transformation, offering a
              bridge between restaurants and their customers.
              <br /> <br />
              Through digital assistants, restaurants can provide customers with
              instant responses to common inquiries. These virtual helpers guide
              the customer through the menu, share nutritional details, help
              locate nearby branches, and even facilitate online ordering such
              as the following -
            </p>
            <ul className="text-lg gap-3 ml-5  flex-col flex list-disc leading-7 font-normal font-inter text-gray-500 text-left  mt-5 mb-8 ">
              <li className="text-gray-200">
                <span className="text-gray-500">
                  “What vegetarian options are available?”
                </span>
              </li>
              <li className="text-gray-200">
                <span className="text-gray-500">
                  “Can you tell me the ingredients of the chicken salad?”{" "}
                </span>
              </li>
              <li className="text-gray-200">
                <span className="text-gray-500">
                  “What is the closest location to me in San Diego?”
                </span>
              </li>
            </ul>
            <p className="text-lg leading-7 font-normal font-inter text-gray-500 text-left  mt-5 mb-16 ">
              Consider a scenario where a customer wants to place a special
              order for a birthday celebration. With a digital assistant, they
              can simply ask, “Can I order a gluten-free cake with a custom
              message?” and receive immediate assistance and confirmation. This
              kind of instant, personalized interaction not only boosts customer
              engagement but also enhances the overall dining experience.
            </p>
            <h2 className="text-3xl font-bold font-inter  leading-10">
              From beginner to expert in 30 seconds
            </h2>
            <p className="text-lg leading-7 font-normal font-inter text-gray-500 text-left  mt-5 mb-8 ">
              By integrating digital assistants into their online platforms,
              restaurants can offer a level of service that sets them apart from
              the competition. From increasing online visibility to fostering
              brand loyalty, digital assistants could be the secret ingredient
              to success in the modern restaurant landscape.
            </p>
            <p className="font-medium text-lg text-[#111827] italic px-4 border-l-4 border-solid">
              “Boosting customer engagement is no small feat, but with digital
              assistants, businesses can offer a transformative experience that
              takes a guest from beginner to expert in a mere 30 seconds..”
            </p>
            <p className="text-lg leading-7 font-normal font-inter text-gray-500 text-left  mt-8 mb-8 ">
              Imagine a restaurant patron who's unfamiliar with the menu or the
              ordering process. Upon opening the restaurant's app, a digital
              assistant promptly greets them. This assistant, armed with
              information and an intuitive conversational interface, guides the
              guest through the menu, offering insights into ingredients,
              allergen information, and pairing suggestions. It can even answer
              complex questions about dietary preferences or the restaurant's
              sustainability practices.
            </p>
            <img src={BlogPic} alt="Blog" className="w-full rounded-xl" />
            <p className="text-lg leading-7 font-normal font-inter text-gray-500 text-left  mt-5 mb-16 ">
              A restaurant owner taps into the future, using Vistry's platform
              to gauge real-time customer sentiment, unlocking insights to
              elevate the dining experience.
            </p>
            <h2 className="text-3xl font-bold font-inter  leading-10">
              Everything you need to transform your brand
            </h2>
            <p className="text-lg leading-7 font-normal font-inter text-gray-500 text-left  mt-8 mb-8 ">
              Within seconds, the guest feels more knowledgeable and confident,
              navigating the menu like an expert and placing an order tailored
              to their liking. The transformation from a novice to a connoisseur
              happens within the blink of an eye, all thanks to a well-crafted
              digital assistant that understands the guest's needs and
              preferences.
            </p>
            <p className="text-lg leading-7 font-normal font-inter text-gray-500 text-left  mt-5 mb-16 ">
              The result is a level of engagement and satisfaction that keeps
              guests returning, proving that digital assistants are indeed a
              recipe for success in today's competitive market.
            </p>
          </div>
        </div>
      </div>
      <Blog />
    </>
  );
};

export default Blog1;
