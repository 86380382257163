import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Blog from "./pages/Blog";
import Blog3 from "./component/Blog3";
import Blog1 from "./component/BlogDetail1";

import Contact from "./component/Contact";
import Demo from "./component/Demo";
import EarlyAccess from "./component/EarlyAccess";
import Navbar from "./component/Navbar";
import Platform from "./component/Platform";
import CustomerAssist from "./pages/CustomerAssist";
import EmployeeAssist from "./pages/EmployeeAssist";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Solution from "./pages/Solution";
import Blog2 from "./component/Blog2";
import ScrollToTop from "./component/Scrolltotop";
import GetStarted from "./component/GetStarted";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import CloverPrivacyPolicy from "./pages/CloverPrivacyPolicy";
import CloverTermsOfService from "./pages/CloverTermsOfService";

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};
const AppContent = () => {
  const location = useLocation();
  const [showDemoOnly, setShowDemoOnly] = useState(true);

  useEffect(() => {
    // Check if the viewport width is below a certain threshold (e.g., 768px for common mobile devices)
    if (location.pathname.includes("demo") && window.innerWidth < 768) {
      setShowDemoOnly(false);
    } else {
      setShowDemoOnly(true);
    }
  }, [location.pathname]);
  return (
    <>
      <ScrollToTop />

      <div className="bg-[rgb(10,33,44)]">
        {showDemoOnly && <Navbar setShowDemoOnly={setShowDemoOnly} />}
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/lumo" element={<CustomerAssist />} />
        <Route path="/zeno" element={<EmployeeAssist />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/price" element={<Pricing />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog1" element={<Blog1 />} />
        <Route path="/blog2" element={<Blog2 />} />
        <Route path="/blog3" element={<Blog3 />} />
        <Route path="/getstarted" element={<GetStarted />} />
        <Route
          path="/privacy"
          element={<PrivacyPolicy/>}
        />
        <Route
          path="/tos"
          element={<TermsOfService/>}
        />        
        <Route
          path="/clover-privacy-policy"
          element={<CloverPrivacyPolicy />}
        />
        <Route
          path="/clover-terms-of-service"
          element={<CloverTermsOfService />}
        />
        {showDemoOnly ? (
          <Route path="/demo" element={<Demo showDemoOnly={showDemoOnly} />} />
        ) : (
          <>
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog1" element={<Blog1 />} />
            <Route path="/blog2" element={<Blog2 />} />
            <Route path="/blog3" element={<Blog3 />} />
            <Route path="/getstarted" element={<GetStarted />} />
            <Route
              path="/privacy"
              element={<PrivacyPolicy/>}
            />
            <Route
              path="/tos"
              element={<TermsOfService/>}
            />
            <Route
              path="/clover-privacy-policy"
              element={<CloverPrivacyPolicy />}
            />
            <Route
              path="/clover-terms-of-service"
              element={<CloverTermsOfService />}
            />
          </>
        )}
      </Routes>
      {showDemoOnly && <EarlyAccess />}
      {showDemoOnly && <Contact />}
    </>
  );
};

export default App;
