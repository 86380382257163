import React from "react";
import customerassist from "../images/Feature example.png";
import employeeassist from "../images/mobile.png";
import Voice from "../images/Voice.png";
import Group2 from "../images/Group2.png";
const CustomerAssist = () => {
  return (
    <div>
      {" "}
      <div className=" bg-gray-50 ">
        <div className="max-w-screen-xl pb-20 sm:pb-3 px-4 sm:px-12 m-auto">
          {" "}
          <div className="pt-10 sm:pt-20">
            <h1 className="text-center relative text-3xl sm:text-5xl leading-10 font-extrabold font-inter text-[#111827] mt-2 z-10">
              Meet Lumo, Our AI Customer Assistant
            </h1>
            <p className="text-xl leading-7 font-normal font-inter text-gray-500 text-center px-1 sm:px-12 mt-5 z-10">
              Unifying Digital and Voice Interactions for Customers
            </p>
          </div>
          <div className="relative flex items-center flex-col-reverse md:flex-row justify-center px-4 sm:px-12 pt-4 pb-10  sm:pt-24 sm:pb-12 lg:flex">
            <div className="w-full flex justify-center pr-0 flex-col lg:w-1/2 sm:pr-6">
              <h1 className="text-3xl sm:text-4xl leading-9 font-extrabold tracking-tight font-inter text-gray-900 text-left">
                First party website
              </h1>
              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-3 text-left">
                Our AI assistant can be embedded right on your site, providing
                immediate, intelligent interactions that reflect your brand's
                voice. Offer menu suggestions, answer queries, or take orders
                directly through your website, creating a seamless user
                experience.
              </p>

              <div className="flex mt-5 sm:mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 4C11 2.89543 11.8954 2 13 2C14.1046 2 15 2.89543 15 4V5C15 5.55228 15.4477 6 16 6H19C19.5523 6 20 6.44772 20 7V10C20 10.5523 19.5523 11 19 11H18C16.8954 11 16 11.8954 16 13C16 14.1046 16.8954 15 18 15H19C19.5523 15 20 15.4477 20 16V19C20 19.5523 19.5523 20 19 20H16C15.4477 20 15 19.5523 15 19V18C15 16.8954 14.1046 16 13 16C11.8954 16 11 16.8954 11 18V19C11 19.5523 10.5523 20 10 20H7C6.44772 20 6 19.5523 6 19V16C6 15.4477 5.55228 15 5 15H4C2.89543 15 2 14.1046 2 13C2 11.8954 2.89543 11 4 11H5C5.55228 11 6 10.5523 6 10V7C6 6.44772 6.44772 6 7 6H10C10.5523 6 11 5.55228 11 5V4Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Seamless Integration
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    Enhance your existing website architecture with one line of
                    code. From menu exploration to immediate ordering, it's a
                    streamlined experience that keeps your guests engaged
                    directly on your site.
                  </p>
                </div>
              </div>
              <div className="flex gap-2 sm:gap-4 mt-10 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_2451_959)">
                      <path
                        d="M11.049 2.92664C11.3483 2.00537 12.6517 2.00538 12.951 2.92664L14.4699 7.60055C14.6038 8.01254 14.9877 8.29148 15.4209 8.29149L20.3354 8.29168C21.3041 8.29172 21.7068 9.53127 20.9232 10.1007L16.9474 12.9895C16.5969 13.2441 16.4503 13.6955 16.5841 14.1075L18.1026 18.7815C18.4019 19.7028 17.3475 20.4689 16.5638 19.8995L12.5878 17.011C12.2373 16.7564 11.7627 16.7564 11.4122 17.011L7.43622 19.8995C6.65252 20.4689 5.5981 19.7028 5.8974 18.7815L7.41589 14.1075C7.54974 13.6955 7.40309 13.2441 7.05263 12.9895L3.07683 10.1007C2.29317 9.53127 2.69592 8.29172 3.66461 8.29168L8.57911 8.29149C9.01231 8.29148 9.39623 8.01254 9.53011 7.60055L11.049 2.92664Z"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2451_959">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Personalized Guest Experience
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    Offer targeted recommendations, answer specific queries, and
                    interact in a manner that reflects your brand's unique
                    personality.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="bg-dotted dotted2"></div> */}

            <div className="w-full lg:w-1/2">
              <div className="mx-auto ">
                <div className="max-w-sm flex-none m-auto sm:max-w-md lg:max-w-2xl">
                  <img
                    src={customerassist}
                    alt="Customer Assistant screenshot"
                    className="mx-auto w-full h-auto rounded-md "
                    data-aos="fade-left"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block items-center sm:flex-col md:flex-row justify-center  px-4 sm:px-12 py-0 lg:flex sm:py-12 ">
            <div className="w-4/5 m-auto lg:m-0 lg:w-1/2">
              <div className="mx-auto flex justify-center md:block">
                <div className="max-w-sm hidden sm:block  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                  <div className="boxBack"></div>

                  <img
                    src={employeeassist}
                    alt="Employee Assistant screenshot"
                    className="mx-auto  w-full h-auto rounded-md  max-h-[680px]"
                    data-aos="fade-right"
                    style={{ maxWidth: "343.2px" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col justify-center mt-0 sm:mt-10 pl-0 lg:mt-0 lg:w-1/2 lg:pl-10">
              <h1 className="text-3xl sm:text-4xl  leading-9 font-extrabold tracking-tight font-inter text-gray-900 mt-10 text-left lg:text-left sm:mt-0 ">
                Mobile
              </h1>

              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-2 text-left">
                With the integration of Vistry's AI assistants into your mobile
                channel, you not only broaden the ways to reach and interact
                with your guests but also create meaningful connections that
                drive loyalty and satisfaction
              </p>
              <div className="max-w-sm  mt-5 block sm:hidden  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                <img
                  src={employeeassist}
                  alt="Employee Assistant screenshot"
                  className="mx-auto  w-full h-auto rounded-md  max-h-[680px] "
                  data-aos="fade-right"
                />
              </div>
              <div className="flex  mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7 8H17M7 12H11M12 20L8 16H5C3.89543 16 3 15.1046 3 14V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V14C21 15.1046 20.1046 16 19 16H16L12 20Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Instant Communication, Anytime, Anywhere
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    From order confirmations to personalized offers, our AI
                    assistants can engage through a mobile browser, offering convenience and
                    personalization like never before.
                  </p>
                </div>
              </div>

              <div className="flex mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Personalized and On-Brand Messaging
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    Every message resonates with your unique identity. From
                    greetings to promotional content, maintain consistency
                    across all communications.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative block items-center flex-col md:flex-row justify-center px-4 sm:px-12 pt-8 pb-10  sm:pt-24 sm:pb-12 lg:flex">
            <div className="w-full flex justify-center pr-0 flex-col lg:w-1/2 sm:pr-6">
              <h1 className="text-3xl sm:text-4xl leading-9 font-extrabold tracking-tight font-inter text-gray-900 text-left">
                Phone
              </h1>
              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-3 text-left">
                From placing orders to seeking information about the menu, our
                intelligent voice system can handle inquiries efficiently,
                providing a human-like interaction that resonates with your
                brand's voice.
              </p>
              <div className="w-full mt-8 lg:w-1/2 block sm:hidden">
                <div className="mx-auto ">
                  <div className="max-w-sm flex-none m-auto sm:max-w-md lg:max-w-2xl">
                    <img
                      src={Voice}
                      alt="Customer Assistant screenshot"
                      className="mx-auto w-full h-auto rounded-md "
                      data-aos="fade-left"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-5 sm:mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 11C19 14.866 15.866 18 12 18M12 18C8.13401 18 5 14.866 5 11M12 18V22M12 22H8M12 22H16M12 14C10.3431 14 9 12.6569 9 11V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V11C15 12.6569 13.6569 14 12 14Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    24/7 Voice Support
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    Our AI assistants are at your service around the clock,
                    ensuring that your guests can reach out and engage with your
                    restaurant whenever they desire.
                  </p>
                </div>
              </div>
              <div className="flex gap-2 sm:gap-4 mt-10 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 5C3 3.89543 3.89543 3 5 3H8.27924C8.70967 3 9.09181 3.27543 9.22792 3.68377L10.7257 8.17721C10.8831 8.64932 10.6694 9.16531 10.2243 9.38787L7.96701 10.5165C9.06925 12.9612 11.0388 14.9308 13.4835 16.033L14.6121 13.7757C14.8347 13.3306 15.3507 13.1169 15.8228 13.2743L20.3162 14.7721C20.7246 14.9082 21 15.2903 21 15.7208V19C21 20.1046 20.1046 21 19 21H18C9.71573 21 3 14.2843 3 6V5Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className="ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Dynamic Call Routing and Management
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    Whether it's a simple order or a more complex inquiry, our
                    system manages calls with precision, ensuring a smooth and
                    satisfying customer experience.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="bg-dotted dotted2"></div> */}

            <div className="w-full lg:w-1/2 hidden sm:block">
              <div className="mx-auto ">
                <div className="max-w-sm flex-none m-auto sm:max-w-md lg:max-w-2xl">
                  <img
                    src={Voice}
                    alt="Customer Assistant screenshot"
                    className="mx-auto w-full h-auto rounded-md "
                    data-aos="fade-left"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block sm:flex-col md:flex-row justify-center  px-4 sm:px-12 py-0 lg:flex sm:py-12">
            <div className="w-4/5 m-auto lg:m-0 lg:w-1/2">
              <div className="mx-auto flex justify-center md:block">
                <div className="max-w-sm hidden sm:block  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                  <div className="boxBack"></div>

                  <img
                    src={Group2}
                    alt="Employee Assistant screenshot"
                    className="mx-auto  w-full h-auto rounded-md  max-h-[680px]"
                    data-aos="fade-right"
                    style={{ maxWidth: "343.2px" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col justify-center mb-0 sm:mb-12 mt-0 sm:mt-10 pl-0 lg:mt-0 lg:w-1/2 lg:pl-10 ">
              <h1 className="text-3xl sm:text-4xl  leading-9 font-extrabold tracking-tight font-inter text-gray-900 mt-10 text-left lg:text-left sm:mt-0 ">
                Kiosk & Drive-Thru
              </h1>

              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-2 text-left">
                Enhance guest experience with Vistry's Kiosk and Drive-Thru
                solutions. Speed, precision, and customization converge for
                seamless ordering and brand alignment.
              </p>
              <div className="max-w-sm  mt-5 block sm:hidden  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                <img
                  src={Group2}
                  alt="Employee Assistant screenshot"
                  className="mx-auto  w-full h-auto rounded-md  max-h-[680px] z-20"
                  data-aos="fade-right"
                />
              </div>
              <div className="flex  mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 18H12.01M7 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Kiosk
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    Guests can browse, customize, and pay—all without waiting in
                    line. Whether upselling or recommending, our AI tailors each
                    interaction, boosting satisfaction and revenue.
                  </p>
                </div>
              </div>

              <div className="flex mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_2333_47737)">
                      <path
                        d="M8.08888 17.3301C8.08888 17.7729 7.90217 18.1977 7.56981 18.5109C7.23745 18.8241 6.78668 19 6.31666 19C5.84664 19 5.39587 18.8241 5.06351 18.5109C4.73116 18.1977 4.54444 17.7729 4.54444 17.3301M8.08888 17.3301C8.08888 16.8872 7.90217 16.4624 7.56981 16.1492C7.23745 15.836 6.78668 15.6601 6.31666 15.6601C5.84664 15.6601 5.39587 15.836 5.06351 16.1492C4.73116 16.4624 4.54444 16.8872 4.54444 17.3301M8.08888 17.3301H15.1778H17.8361M4.54444 17.3301H2.32917C1.97665 17.3301 1.63857 17.1981 1.3893 16.9632C1.14004 16.7283 1 16.4098 1 16.0776V11.9792C1 11.4822 1.18503 11.003 1.51904 10.635L5.13066 6.65584C5.50975 6.23818 6.04757 6 6.61162 6H13.7009M21.3805 17.3301C21.3805 17.7729 21.1938 18.1977 20.8615 18.5109C20.5291 18.8241 20.0783 19 19.6083 19C19.1383 19 18.6875 18.8241 18.3552 18.5109C18.0228 18.1977 17.8361 17.7729 17.8361 17.3301M21.3805 17.3301C21.3805 16.8872 21.1938 16.4624 20.8615 16.1492C20.5291 15.836 20.0783 15.6601 19.6083 15.6601C19.1383 15.6601 18.6875 15.836 18.3552 16.1492C18.0228 16.4624 17.8361 16.8872 17.8361 17.3301M21.3805 17.3301H22.7097C23.4434 17.3301 24.0436 16.7689 23.9975 16.0787V11.2069M13.7009 6H16.7752C17.7781 6 18.7444 6.37672 19.4826 7.05552L23.9975 11.2069M13.7009 6V11.2069H23.9975"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2333_47737">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Drive-Thru
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    Say goodbye to misunderstood orders and lengthy waits. With
                    advanced voice recognition and integration with your menu,
                    our system captures orders precisely and quickly, routing
                    them to the kitchen without a hitch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerAssist;
