import React from "react";
import olo from "../images/olo.svg";
import clover from "../images/clover.svg";
import lunch from "../images/lunchbox.svg";
import Square from "../images/Square,_Inc._logo.svg";
import toast from "../images/Toast.svg";
import Qu from "../images/Qu.svg";
const Market = () => {
  const images1 = [olo, lunch, Qu, clover, Square, toast];
  return (
    <div className=" bg-gray-50 ">
      <div className="max-w-screen-xl pb-3 px-4 sm:px-12 m-auto">
        {" "}
        <p className="text-[#111827] text-center text-base leading-6 font-semibold tracking-wide uppercase font-inter pt-[95px]">
          integrations with ONLINE ORDERING platforms
        </p>
        <div className=" flex-wrap  flex gap-[2px] mt-8">
          {images1.map((data) => (
            <div className="flex m-auto sm:m-0 justify-center bg-gray-100  w-[49%]  sm:w-[32.3%] max-w-full  h-[97px] sm:h-[112px] p-8">
              {" "}
              <img alt="data_img" src={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Market;
