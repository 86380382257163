import { React, useState } from "react";
import early1 from "../images/early1.svg";
import early2 from "../images/early2.svg";
import early3 from "../images/early3.svg";
import backgroundI from "../images/background.webp";

import axios from "axios";

const EarlyAccess = () => {
  const [email, setEmail] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("idle");
  const cards = [
    {
      image: early1,
      title: "14-Day Unrestricted Access",

      description:
        "Explore all features without limitations on use, at no cost.",
    },
    {
      image: early3,
      title: "Dedicated Support",
      description:
        "We're with you every step of the way to ensure smooth implementation.",
    },
    {
      image: early2,
      title: "Zero Commitment",
      description:
        "Love it? Choose a plan that suits you. No obligation to continue.",
    },
  ];

  const getIpAddress = async () => {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  };

  const submitToHubspot = async (email, ipAddress) => {
    const portalId = "20656462";
    const formGuid = "abcff9c3-e50b-4bc9-9f86-e878bb872498";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        ipAddress: ipAddress,
        fields: [
          {
            name: "email",
            value: email,
          },
        ],
      },
      config
    );
    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionStatus("submitting");
    const ipAddress = await getIpAddress();
    try {
      const response = await submitToHubspot(email, ipAddress);
      if (response.status === 200) {
        setSubmissionStatus("submitted");

        setEmail("");
      } else {
        setSubmissionStatus("error");
      }
    } catch (error) {
      setSubmissionStatus("error");
    }
  };
  return (
    <div
      className="bg-no-repeat w-full relative bg-cover lg:max-h-[524px]"
      style={{ backgroundImage: `url(${backgroundI})` }}
      loading="lazy"
    >
      <div className="absolute inset-0 bg-[#0A202B] mix-blend-multiply"></div>
      <div className="pt-24 sm:pt-32   relative z-20 max-w-screen-xl m-auto px-4 sm:px-24 ">
        <div className="block   sm:flex">
          <div className="w-full sm:w-1/2">
            {" "}
            <h1 className="text-4xl sm:text-6xl leading-none font-extrabold font-inter text-white">
              Transform Your Brand
            </h1>
            <p className="text-xl leading-7 font-normal font-inter text-gray-300 mt-6">
              Discover the power of personalized, intelligent assistance with a
              trial that’s crafted to reflect your brand's unique voice and
              identity.
            </p>
          </div>
          <div className="w-full sm:w-1/2 m-auto gap-5 flex place-content-end">
            <input
              className="rounded-md border border-gray-300 bg-white shadow-sm p-3 items-center mt-[24px] max-w-[368px] w-full"
              id="email"
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleSubmit}
              className="rounded-md bg-[#FFC300] text-base font-medium shadow-sm flex p-3.5 justify-center items-center w-[104px] mt-[24px] hover:bg-yellow-400"
            >
              Submit
            </button>
            <div>
              {submissionStatus === "submitted" && (
                <p className="text-green-500 font-inter mt-[24px]">
                  Submission successful!
                </p>
              )}
              {submissionStatus === "error" && (
                <p className="text-red-500 font-inter mt-[24px]">
                  An error occurred. Please try again.
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="block justify-between gap-3 pt-4 sm:pt-20  z-20 lg:flex ">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`rounded-2xl border mt-16  border-white bg-[#0A202B] shadow-xl px-6 pt-12 h-[212px] max-w-[352px] sm:mt-${
                index > 0 ? "10" : "0"
              } sm:ml-${index > 0 ? "12" : "0"} lg:mt-${
                index > 0 ? "0" : "10"
              }  m-auto lg:m-0`}
              data-aos="flip-left"
            >
              {" "}
              <img
                alt={card.title}
                src={card.image}
                style={{ marginTop: "-80px" }}
                loading="lazy"
              />
              {/* <div className="flex flex-col justify-between h-full"> */}
              <div>
                <h3 className="text-white font-inter text-xl mt-8">
                  {card.title}
                </h3>
                <p className="text-white text-base font-normal font-inter mt-4">
                  {card.description}
                </p>
              </div>
              {/* <p className="text-white text-base font-normal font-inter mb-5">

                  Enroll today →
                </p>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EarlyAccess;
