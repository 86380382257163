import React from "react";


const PrivacyPolicy = () => {
    const pdfUrl = "/assets/Privacy-Policy.pdf"; // Adjusted path

  return (
    <div>
      <div className="isolate overflow-hidden ">
        <div className="mx-auto max-w-7xl px-6 pb-12 pt-12 text-center sm:pt-14 lg:px-8">
          <div className="">
          <p className="text-center text-3xl leading-6 font-semibold tracking-wide font-inter mb-8">
              Privacy Policy
            </p>
            <iframe 
              src={pdfUrl}
              width="100%" 
              height="1000px" 
              frameborder="0" 
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
