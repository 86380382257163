import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const GetStarted = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Olo");
  const options = [
    "Olo",
    "Lunchbox",
    "Qu",
    "Square",
    "Toast",
    "Clover",
    "Other",
  ]; // Add more options as needed
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comment, setComment] = useState("");
  const [numLocations, setNumLocations] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("idle");
  const [isSubmit, setIssumbit] = useState(false);
  const [validEmail, setValidEmail] = useState("");

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsMenuOpen(false);
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const getIpAddress = async () => {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  };

  const submitToHubspot = async (ipAddress) => {
    const portalId = "20656462";
    const region = "na1";
    const formId = "f8835cf1-fd08-426d-aa03-2a29b43e5dae";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
      {
        portalId,
        region,
        formId,
        ipAddress: ipAddress,
        fields: [
          {
            name: "firstName",
            value: firstName,
          },
          { name: "lastName", value: lastName },
          { name: "company", value: company },
          { name: "jobtitle", value: role },
          { name: "email", value: validEmail },
          { name: "phoneNumber", value: phoneNumber },
          { name: "aboutUs", value: comment },
          { name: "restaurantLocations", value: numLocations },
          { name: "onlineOrdering", value: selectedOption },
        ],
      },
      config
    );
    return response;
  };

  const handleSubmit = async (e) => {
    setIssumbit(true);
    e.preventDefault();
    if (
      firstName !== "" &&
      firstName.length <= 20 &&
      lastName !== "" &&
      lastName.length <= 20 &&
      company !== "" &&
      company.length <= 30 &&
      role !== "" &&
      role.length < 30 &&
      validEmail &&
      phoneNumber !== "" &&
      phoneNumber.length <= 12 &&
      isChecked === true
    ) {
      setSubmissionStatus("submitting");
      const ipAddress = await getIpAddress();
      try {
        const response = await submitToHubspot(ipAddress);
        if (response.status === 200) {
          setSubmissionStatus("submitted");

          setEmail("");
          setFirstName("");
          setLastName("");
          setRole("");
          setCompany("");
          setNumLocations("");
          setValidEmail("");
          setPhoneNumber("");
          setComment("");
          setNumLocations("");
          setIssumbit(false);
        } else {
          setSubmissionStatus("error");
        }
      } catch (error) {
        setSubmissionStatus("error");
      }
    } else {
      setSubmissionStatus("error");
    }
  };
  function isValidEmail(email) {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailPattern.test(email);
  }
  console.log(isSubmit === true && (lastName === "" || lastName.length > 20));
  return (
    <div>
      <div className="max-w-screen-lg m-auto px-4 sm:px-12">
        <div className="py-8  " name="demo">
          <div className="max-w-screen-lg px-0 sm: m-auto">
            <h1 className="text-center text-4xl sm:text-5xl leading-10 font-extrabold font-inter text-[#111827] mt-4">
              Get Started
            </h1>
            <p className="text-xl leading-7 font-normal font-inter text-gray-500 text-center  mt-8 mb-16 ">
              By sharing your contact information we can see how Vistry can best
              help your business.
            </p>
            <div className=" pb-12">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      value={firstName}
                      className={`block w-full h-[50px] p-2 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1   ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6 
                      ${
                        isSubmit === true &&
                        (firstName === "" || firstName.length > 20)
                          ? " ring-red-700 "
                          : ""
                      }`}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      value={lastName}
                      id="last-name"
                      autoComplete="family-name"
                      className={`block w-full h-[50px] p-2 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6 ${
                        isSubmit === true &&
                        (lastName === "" || lastName.length > 20)
                          ? " ring-red-700 border-solid"
                          : ""
                      }`}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Company
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={(e) => {
                        setCompany(e.target.value);
                      }}
                      value={company}
                      autoComplete="email"
                      className={`block w-full h-[50px] p-2 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6 ${
                        isSubmit === true &&
                        (company === "" || company.length > 30)
                          ? " ring-red-700 border-solid"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Role
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                      value={role}
                      type="email"
                      autoComplete="email"
                      className={`block w-full h-[50px] p-2 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6 ${
                        isSubmit === true && (role === "" || role.length > 30)
                          ? " ring-red-700 border-solid"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      onChange={(e) => {
                        const newEmail = e.target.value;
                        setEmail(newEmail);
                        setValidEmail(isValidEmail(newEmail) ? newEmail : "");
                      }}
                      value={email}
                      type="email"
                      autoComplete="email"
                      className={`block w-full h-[50px] p-2 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6 ${
                        isSubmit && !validEmail
                          ? " ring-red-700 border-solid"
                          : ""
                      }`}
                    />
                    {/* {!validEmail && isSubmit && (
                      <p className="block text-sm font-medium leading-6 text-red-700">
                        {" "}
                        Email is not valid
                      </p>
                    )} */}
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="phone-number"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Phone number
                  </label>
                  <div className="relative mt-2.5">
                    <div className="absolute inset-y-0 left-0 flex items-center">
                      <select
                        id="country"
                        name="country"
                        className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-2 text-gray-400   sm:text-sm"
                      >
                        <option>US</option>
                        <option>CA</option>
                        <option>EU</option>
                      </select>
                    </div>
                    <input
                      type="tel"
                      name="phone-number"
                      id="phone-number"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      autoComplete="tel"
                      className={`block w-full h-[50px] p-2 rounded-md border-0 px-3.5 py-2 pl-20 text-gray-700 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6 ${
                        isSubmit === true &&
                        (phoneNumber === "" || phoneNumber.length > 12)
                          ? " ring-red-700 border-solid"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="comment"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    How did you hear about us?{" "}
                    <span className="text-gray-400"> (optional) </span>
                  </label>
                  <div className="mt-2">
                    <textarea
                      rows="4"
                      name="comment"
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      value={comment}
                      id="comment"
                      className="block w-full  p-2 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Number of restaurant locations?{" "}
                    <span className="text-gray-400"> (optional) </span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      onChange={(e) => {
                        setNumLocations(e.target.value);
                      }}
                      value={numLocations}
                      type="email"
                      autoComplete="email"
                      className="block w-full h-[50px] p-2 rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <div className="mt-2">
                    <div>
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Online Ordering API{" "}
                        <span className="text-gray-400"> (optional) </span>
                      </label>
                      <div className="relative mt-2">
                        <button
                          type="button"
                          className="relative w-full h-[38px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-700 shadow-sm ring-1  ring-gray-300 focus:outline-none focus:ring-2  sm:text-sm sm:leading-6"
                          aria-haspopup="listbox"
                          aria-expanded={isMenuOpen}
                          aria-labelledby="listbox-label"
                          onClick={handleToggleMenu}
                        >
                          <span className="block truncate">
                            {selectedOption}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg
                              className="h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>

                        {isMenuOpen && (
                          <ul
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            tabIndex="-1"
                            role="listbox"
                            aria-labelledby="listbox-label"
                          >
                            {options.map((option, index) => (
                              <li
                                key={index}
                                className={`${
                                  option === selectedOption
                                    ? "text-base font-semibold text-gray-700"
                                    : "text-gray-700"
                                } relative cursor-default select-none py-2 pl-3 pr-9`}
                                // role="option"
                                onClick={() => handleOptionSelect(option)}
                              >
                                <span
                                  className={`${
                                    option === selectedOption
                                      ? "font-semibold"
                                      : "font-normal"
                                  } block truncate`}
                                >
                                  {option}
                                </span>
                                {option === selectedOption && (
                                  <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
                                        fill="#0A202B"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center sm:col-span-6">
                  <label
                    htmlFor="toggle"
                    className="flex items-center cursor-pointer"
                  >
                    <div className="relative">
                      <input
                        type="checkbox"
                        id="toggle"
                        className="sr-only"
                        checked={isChecked}
                        onChange={handleToggle}
                      />
                      <div
                        className={`w-10 h-6 bg-gray-300 rounded-full shadow-inner${
                          isChecked && " bg-[#374151] "
                        }`}
                      ></div>
                      <div
                        className={`absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0 ${
                          isChecked ? "transhtmlForm translate-x-full" : ""
                        }`}
                      ></div>
                    </div>
                    <div
                      className={`ml-3 text-gray-500 text-base  ${
                        isSubmit === true && isChecked === false
                          ? " text-red-500 "
                          : ""
                      } ${
                        isChecked ? "font-medium text-gray-900" : "font-medium"
                      }`}
                    >
                      By selecting this, you agree to the{" "}
                      <span
                        className={`underline text-gray-900 ${
                          isSubmit === true && isChecked === false
                            ? " text-red-500 "
                            : ""
                        }`}
                      >
                        Privacy Policy
                      </span>{" "}
                      and
                      {""}
                      <span
                        className={`underline text-gray-900 ml-1 ${
                          isSubmit === true && isChecked === false
                            ? " text-red-500 "
                            : ""
                        }`}
                      >
                        Cookie Policy
                      </span>
                      .
                    </div>
                  </label>
                </div>
                <div className="sm:col-span-6">
                  {/* {submissionStatus === "error" && && isSubmit(
                    <p className="text-red-500 font-inter text-base font-medium mt-[12px]">
                      Please fill the required fields
                    </p>
                  )}{" "} */}
                  {submissionStatus === "submitted" && isSubmit && (
                    <p className="text-green-500 font-inter text-base font-medium mt-[12px]">
                      Submission successful!
                    </p>
                  )}
                </div>
              </div>

              <Link
                className="test1 !mt-0 HeroBtn m-auto flex justify-center !w-full hover:bg-yellow-500"
                to="customerassistant"
                onClick={handleSubmit}
              >
                Let's talk
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
