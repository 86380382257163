import React from "react";
import { Link } from "react-router-dom";
import Blog1 from "../images/Blog1.png";
import Blog2 from "../images/Blog2.png";
import Blog3 from "../images/Blog3.png";
const Blog = () => {
  const data = [
    {
      img: Blog1,
      tag: "Article",
      head: "Boosting Customer Engagement with Digital Assistants",
      para: "The rise of digital platforms and online ordering has redefined the restaurant industry. Digital assistants have emerged as key players in this transformation, offering a bridge between restaurants and their customers.",
      tool: "Strategy",
      date: "Aug 16, 2023 · 6 min read",
      ProductIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="roundedSvg"
          fill="none"
        >
          <path
            d="M21 13.2554C18.2207 14.3805 15.1827 15 12 15C8.8173 15 5.7793 14.3805 3 13.2554M16 6V4C16 2.89543 15.1046 2 14 2H10C8.89543 2 8 2.89543 8 4V6M12 12H12.01M5 20H19C20.1046 20 21 19.1046 21 18V8C21 6.89543 20.1046 6 19 6H5C3.89543 6 3 6.89543 3 8V18C3 19.1046 3.89543 20 5 20Z"
            stroke="#F9FAFB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      img: Blog2,
      tag: "Article",
      head: "Top 5: How Conversational UX is Revolutionizing Menus & Order Flows ",
      para: "The future of dining is taking a conversational turn. As technology advances, conversational user experiences (UX) are emerging as vital components in enhancing customer engagement.",
      tool: "Product",
      date: "Jul 10, 2023 · 4 min read",
      ProductIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          className="roundedSvg"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M14.333 10L12.333 11M12.333 11L10.333 10M12.333 11V13.5M20.333 7L18.333 8M20.333 7L18.333 6M20.333 7V9.5M14.333 4L12.333 3L10.333 4M4.33301 7L6.33301 6M4.33301 7L6.33301 8M4.33301 7V9.5M12.333 21L10.333 20M12.333 21L14.333 20M12.333 21V18.5M6.33301 18L4.33301 17V14.5M18.333 18L20.333 17V14.5"
            stroke="#F9FAFB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      img: Blog3,
      tag: "Article",
      head: "Embracing 1st Party Services: Why Customers Prefer Direct Engagement",
      para: "In an increasingly digitalized world, the way people interact with restaurants is undergoing a significant shift. While third-party services have brought convenience, there's a trend towards consumers preferring 1st party services.",
      tool: "Strategy",
      date: "Jun 12, 2023 · 11 min read",
      ProductIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="roundedSvg"
          fill="none"
        >
          <path
            d="M21 13.2554C18.2207 14.3805 15.1827 15 12 15C8.8173 15 5.7793 14.3805 3 13.2554M16 6V4C16 2.89543 15.1046 2 14 2H10C8.89543 2 8 2.89543 8 4V6M12 12H12.01M5 20H19C20.1046 20 21 19.1046 21 18V8C21 6.89543 20.1046 6 19 6H5C3.89543 6 3 6.89543 3 8V18C3 19.1046 3.89543 20 5 20Z"
            stroke="#F9FAFB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];
  return (
    <div>
      <div className="bg-white py-12 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h1 className="text-center relative text-4xl sm:text-5xl leading-10 font-extrabold font-inter text-[#111827] mt-2 z-10">
            From the blog
          </h1>
          <p className="text-xl leading-7 font-normal font-inter text-gray-400 text-center px-0 sm:px-12 mt-5 -mb-5 sm:mb-0 z-10">
            Empowering Restaurants with Smart Assistants
          </p>
          <div className="mx-auto mt-16 block lg:grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {data.map((data, index) => (
              <Link to={`/Blog${index + 1}`}>
                <article
                  className="flex flex-col shadow-neutral-200  rounded-2xl items-start justify-between mt-5 sm:mt-0 h-auto sm:h-[520px]"
                  style={{
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div className="relative w-full">
                    <img
                      src={data?.img}
                      alt="asdsad"
                      className="aspect-[16/9] max-h-[192px] w-full rounded-2xl rounded-br-none rounded-bl-none bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
                  </div>
                  <div className="max-w-xl p-6">
                    <div className=" flex items-center gap-x-4 text-xs">
                      {data?.tag}
                    </div>
                    <div className="group relative">
                      <h3 className="mt-2 text-xl font-semibold leading-6 text-[#111827] text-inter group-hover:text-gray-600">
                        {/* <a href="#"> */}
                        <span className="absolute inset-0"></span>
                        {data.head}
                        {/* </a> */}
                      </h3>
                      <p className="mt-3  !text-sm leading-6 !text-[#6B7280]">
                        {data.para}
                      </p>
                    </div>
                    <div className="relative mt-8 flex items-center gap-x-4">
                      {/* <img
                        src={data.ProductIcon}
                        alt=""
                        className="h-10 w-10 rounded-full bg-gray-100 "
                      /> */}
                      {data.ProductIcon}

                      <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">
                          {/* <a href="#"> */}
                          <span className="absolute inset-0"></span>
                          {data.tool}
                          {/* </a> */}
                        </p>
                        <p className="text-gray-600">{data.date}</p>
                      </div>
                    </div>
                  </div>
                </article>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
