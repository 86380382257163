import { React } from "react";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";

import demo from "../images/Desktop.png";
import art from "../images/Art2.png";
import header from "../images/BrowserHeader.png";
//console.log(header);

const Demo = () => {

  const widgetSrc = process.env.REACT_APP_WIDGET_SRC;
  return (
    <div>
      <Element name="test1" className="element"></Element>
      <div className="sm:py-8 sm:bg-[#0A202B] " name="demo">
        <div className=" px-0 m-auto">
          <p className="text-[#E1E1E1] text-center text-base leading-6  font-semibold tracking-wider uppercase font-inter pt-16 hidden sm:block ">
            LumoChat Product Demo
          </p>
          <h1 className="text-center text-4xl sm:text-5xl leading-10 font-extrabold font-inter text-[#F9FAFB] mt-4 hidden sm:block ">
            Enhance your customer experience
          </h1>
          <p className="text-xl leading-7 font-normal font-inter text-gray-500 text-center px-12 mt-5 mb-16 hidden sm:block ">
            Try this demo to see how Vistry can present customers with helpful
            information. <br />By using our demo, you are consenting to our 
            <Link className="text-[#F9FAFB] hover:underline text-lg font-bold"
              to="/tos"> Terms of Service </Link> and 
            <Link className="text-[#F9FAFB] hover:underline text-lg font-bold"
              to="/privacy"> Privacy Policy </Link>.
          </p>

          <img
            src={art}
            alt="Better Burritos Homepage"
            className="ml-auto art hidden sm:block "
            style={{
              marginTop: "-200px",
              marginRight: "10px",
              position: "absolute",
              zIndex: "9999",
              right: "0",
            }}
          />

          <div className="relative  flex justify-center overflow-x-visible ">
            <div className="relative w-full sm:min-w-[750px]">
              { }{" "}
              <img
                src={demo}
                alt="Better Burritos Homepage"
                className="w-full bg-demo h-auto rounded-md mt-0 mb-5 hidden sm:block "
                loading="lazy"
              />

              <div id="your-iframe-id" className="iframe-container">
                <img
                  src={header}
                  alt="Better Burritos Homepage"
                  className="w-full hidden sm:block"
                  style={{
                    height: "92px",
                  }}
                />{" "}

                <iframe
                  src={widgetSrc}
                  className="mobile-Widget"
                  allowFullScreen
                  title="Chat Wiget Demo"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Demo;