import React from "react";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div>
      <div className="isolate overflow-hidden ">
        <div className="mx-auto max-w-7xl px-6 pb-96 pt-12 text-center sm:pt-14 lg:px-8">
          <div className="">
            <p className="text-[#4B5563] text-center text-base leading-6 font-semibold tracking-wide uppercase font-inter ">
              Pricing
            </p>
            <h1 className="text-center relative text-3xl sm:text-5xl leading-8 sm:leading-10 font-extrabold font-inter text-[#111827] mt-2 z-10">
              Transform Your Restaurant Experience
            </h1>
            <p className="text-xl leading-7 font-normal font-inter text-gray-500 text-center px-0 mt-5 z-10">
              Our cutting-edge AI assistants are crafted to deliver measurable
              results.
            </p>
          </div>
        </div>
        <div className="flow-root bg-white pb-24 sm:pb-32">
          <div className="-mt-[350px] sm:-mt-80">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-lg grid-cols-1 gap-6 lg:max-w-5xl lg:grid-cols-2">
                <div className="flex flex-col justify-between rounded-lg bg-white p-8 shadow-xl  sm:p-10">
                  <div>
                    <h3
                      id="tier-hobby"
                      className="text-sm uppercase bg-[#FFC300] py-0 px-4 rounded-2xl w-max font-medium leading-7 text-[#0A202B]"
                    >
                      Lumo, our customer assistant ai
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-4xl sm:text-6xl font-bold tracking-tight text-gray-900">
                        Channel
                      </span>
                      <span className="text-lg sm:text-xl font-medium leading-7 text-gray-500">
                        / per location
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      Channels include 1st-party website, Mobile, SMS, Phone, Kiosk or
                      Drive-Thru
                    </p>
                    <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Handles complex orders
                      </li>
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Takes customer complaints
                      </li>
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Implementation services from Vistry
                      </li>
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="w-[40px]   sm:w-max"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Integrated with Qu, Olo, Thanx, Square, Clover, and
                        Lunchbox
                      </li>
                    </ul>
                  </div>
                  <Link
                    to="/getstarted"
                    aria-describedby="tier-hobby"
                    className="mt-8 block rounded-md bg-[#0A202B] px-3.5 py-2 text-center text-base font-normal leading-6 text-white shadow-sm hover:bg-[#0d1c23] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0A202B]"
                  >
                    Get started
                  </Link>
                </div>
                <div className="flex flex-col justify-between rounded-lg bg-white p-8 shadow-xl  sm:p-10">
                  <div>
                    <h3
                      id="tier-team"
                      className="text-sm uppercase bg-[#FFC300] py-0 px-4 rounded-2xl w-max font-medium leading-7 text-[#0A202B]"
                    >
                      Zeno, our employee assistant ai
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-4xl sm:text-6xl font-bold tracking-tight text-gray-900">
                        Employee
                      </span>
                      <span className="text-lg sm:text-xl font-medium leading-7 text-gray-500">
                        / per location
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      Reduce Training Costs and Elevate Efficiency with Simple
                      Integration
                    </p>
                    <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="w-[40px] -ml-[2px] sm:-ml-0  sm:w-max"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Integrate your manuals, procedures and guides
                      </li>
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Enhances team communication
                      </li>
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Gather feedback from employees
                      </li>
                      <li className="flex gap-x-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="w-[40px] -ml-[2px] sm:-ml-0  sm:w-max"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#10B981"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Insight into employee bottlenecks and challenges
                      </li>
                    </ul>
                  </div>
                  <Link
                    to="/getstarted"
                    aria-describedby="tier-team"
                    className="mt-8 block rounded-md bg-[#0A202B] px-3.5 py-2 text-center text-base font-normal leading-6 text-white shadow-sm hover:bg-[#0d1c23] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0A202B]"
                  >
                    Get started
                  </Link>
                </div>

                <div className="flex mt-12 sm:mt-0  flex-col items-start bg-gray-200 gap-x-8 gap-y-6 rounded-xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                  <div className="lg:min-w-0 lg:flex-1">
                    <h3 className="text-sm uppercase bg-white py-0 px-4 rounded-2xl w-max font-medium leading-7 text-[#0A202B]">
                      Bundle and Save
                    </h3>
                    <p className="mt-4 text-base leading-7 text-gray-600">
                      Combine Lumo and Zeno, our Customer and Employee Assistants, 
                      and receive an exclusive discount.
                    </p>
                  </div>
                  <a
                    href="mailto:contact@vistry.ai"
                    style={{
                      boxShadow:
                        " 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);",
                    }}
                    className="rounded-md w-full sm:w-max text-center  bg-white px-3.5 py-2 text-sm font-semibold leading-6 text-[#111827]"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
