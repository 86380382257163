
import { React, Suspense, lazy } from "react";
import "typeface-inter";
import Market from ".././component/Market";
const Admin = lazy(() => import(".././component/Admin"));
const Hero = lazy(() => import(".././component/Hero"));
const Solution = lazy(() => import(".././component/Solution"));
export default function Home() {
  //   const [setDemo] = useState(false);
  //   useEffect(() => {
  //     if (window?.innerWidth > 760) {
  //       setDemo(true);
  //     }
  //   }, []);

  const renderLoader = () => <p></p>;
  return (
    <>
      <div className="bg-[rgb(10,33,44)]">
        <Suspense fallback={renderLoader()}>
          <Hero />
        </Suspense>
      </div>
      <Suspense fallback={renderLoader()}>
        <Market />
        <Solution />
        {/* {demo && <Demo />} */}
        <Admin />
      </Suspense>
    </>
  );
}
