import React from "react";
import admin1 from "../images/Brand.svg";
import admin2 from "../images/Brand1.svg";
import admin3 from "../images/Brand2.svg";
import admin4 from "../images/Brand3.svg";
const Platform = () => {
  return (
    <div>
      {" "}
      <div className=" bg-gray-50 ">
        <div className="relative px-4  py-2 sm:py-20 sm:px-12 max-w-screen-xl m-auto">
          <div className="">
            <h1 className="text-left sm:text-center relative text-3xl  sm:text-5xl leading-10 font-extrabold font-inter text-[#111827] mt-8 sm:mt-2 z-10">
              Our Conversational AI Platform
            </h1>
            <p className="text-xl leading-7 font-normal font-inter text-gray-500 text-left sm:text-center px-0 sm:px-12 mt-5 z-10">
              Unifying Digital and Voice Interactions for Brand Leaders
            </p>
          </div>
          <div
            className="relative block grid-cols-4 md:grid-cols-4 gap-8 px-0 sm:px-12 pb-10 sm:py-20 lg:grid"
            data-aos="fade-right"
          >
            <div className="col-span-2 z-20 mt-16 sm:mt-0 hidden sm:block">
              <h2 className="text-3xl  sm:text-5xl flex items-center h-full z-50 leading-[48px] font-extrabold  font-inter text-left">
                Enterprise-ready <br /> features just for you
              </h2>
            </div>
            <div className="bg-dotted dotted3"></div>

            <div className="block grid-cols-2 col-span-2 lg:grid mt-12 sm:mt-5 lg:mt-0">
              <div className="mt-0 gap-3 items-start  sm:text-left flex sm:block ">
                <img
                  alt="Data Privacy & Security"
                  className="m-0"
                  src={admin4}
                  loading="lazy"
                />
                <div>
                  {" "}
                  <h3 className="text-lg leading-6 font-medium font-inter text-gray-900 mt-0 sm:mt-5 sm:text-left">
                    Data Privacy & Security
                  </h3>
                  <p className="mt-2 text-gray-500 font-inter text-base pr-0 sm:pr-2">
                    Your data is yours. All tailored to your restaurant and
                    never to be sold.
                  </p>
                </div>
              </div>
              <div className=" flex gap-3 items-start sm:block  sm:text-left mt-5 sm:mt-0">
                <img
                  alt="System Monitoring"
                  className="m-0"
                  src={admin3}
                  loading="lazy"
                />
                <div>
                  <h3 className="text-lg leading-6 font-medium font-inter text-gray-900 mt-0 sm:mt-5">
                    System Monitoring
                  </h3>
                  <p className="mt-2 text-gray-500 font-inter text-base pr-0 sm:pr-2">
                    Track chatbot performance to prevent hallucinations,
                    optimize the system over time, and highlight operational
                    pain points.
                  </p>
                </div>
              </div>
              <div className="flex gap-3 items-start sm:block mt-5 text-center sm:text-left lg:mt-16">
                <img
                  alt="Flexibility & Control"
                  className="m-0"
                  src={admin1}
                  loading="lazy"
                />
                <div>
                  {" "}
                  <h3 className="text-lg leading-6 text-left font-medium font-inter text-gray-900 mt-0 sm:mt-5">
                    Multilingual System
                  </h3>
                  <p className="mt-2 text-gray-500 text-left font-inter text-base pr-0 sm:pr-2">
                    Data insights and feedback from your employees, no matter
                    what language they speak.
                  </p>
                </div>
              </div>
              <div className="mt-5 gap-3 items-start flex sm:block text-center sm:text-left lg:mt-16">
                <img
                  alt="Multilingual System"
                  className="m-0"
                  src={admin2}
                  loading="lazy"
                />
                <div>
                  {" "}
                  <h3 className="text-lg leading-6 text-left font-medium font-inter text-gray-900 mt-0 sm:mt-5">
                    Flexibility & Control
                  </h3>
                  <p className="mt-2 text-gray-500 text-left font-inter text-base pr-0 sm:pr-2">
                    {" "}
                    Govern and control the knowledge base. Take advantage of
                    multiple language models that work together.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
