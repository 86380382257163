import { React } from "react";
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className="bg-gray-900  sm:pt-20" data-aos="fade-right">
      <div className="flex justify-center max-w-screen-xl m-auto px-0 sm:px-12 sm:block">
        <footer>
          <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10  sm:py-12 lg:px-8">
            <p className="mt-8 text-center text-gray-400 font-normal font-inter text-base leading-6">
              &copy; 2023 Vistry, Inc. All rights reserved. | Contact: <a href="tel:+18449242850">(844) 924-2850</a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Contact;
