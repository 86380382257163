import React from "react";
import customerassist from "../images/employePage1.png";
import employeeassist from "../images/employePage2.png";
import Voice from "../images/employePage3.png";
import Group2 from "../images/employePage4.png";

const EmployeeAssist = () => {
  return (
    <div>
      {" "}
      <div className=" bg-gray-50 ">
        <div className="max-w-screen-xl pb-12 px-4 sm:px-12 m-auto">
          {" "}
          <div className=" pt-10 sm:pt-20 block">
            <h1 className="text-center relative text-3xl sm:text-5xl leading-10 font-extrabold font-inter text-[#111827] mt-2 z-10">
              Meet Zeno, Our AI Employee Assistant
            </h1>
            <p className="text-xl leading-7 font-normal font-inter text-gray-500 text-center px-1 sm:px-12 mt-5 z-10">
              Unifying Digital and Voice Interactions for Employees
            </p>
          </div>
          <div className="relative  items-center flex-col-reverse md:flex-row justify-center px-4 sm:px-12 pt-4 pb-10  sm:pt-24 sm:pb-12 flex">
            <div className="w-full flex justify-center pr-0 flex-col lg:w-1/2 sm:pr-6">
              <h1 className="text-3xl sm:text-4xl leading-9 font-extrabold tracking-tight font-inter text-gray-900 text-left">
                Instant Access to Manuals & Procedures
              </h1>
              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-6 sm:mt-3 text-left">
                With Vistry's Employee Assistant, your entire operational
                handbook is at your employee’s fingertips. Need to know how to
                execute a specific task? Just ask, and receive instant, concise
                instructions. It's your go-to guide for everything in your
                restaurant, simplifying training and daily tasks.
              </p>

              <div className="flex mt-5 sm:mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 11H5M19 11C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13C3 11.8954 3.89543 11 5 11M19 11V9C19 7.89543 18.1046 7 17 7M5 11V9C5 7.89543 5.89543 7 7 7M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7M7 7H17"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Simplifies Onboarding & Training
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    New staff are up to speed quickly, reducing training time
                    and costs..
                  </p>
                </div>
              </div>
              <div className="flex gap-2 sm:gap-4 mt-10 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9.70711 13.2929C9.31658 12.9024 8.68342 12.9024 8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071L9.70711 13.2929ZM11 16L10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L11 16ZM15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929C15.3166 10.9024 14.6834 10.9024 14.2929 11.2929L15.7071 12.7071ZM18 7V19H20V7H18ZM17 20H7V22H17V20ZM6 19V7H4V19H6ZM7 6H9V4H7V6ZM15 6H17V4H15V6ZM7 20C6.44772 20 6 19.5523 6 19H4C4 20.6569 5.34315 22 7 22V20ZM18 19C18 19.5523 17.5523 20 17 20V22C18.6569 22 20 20.6569 20 19H18ZM20 7C20 5.34315 18.6569 4 17 4V6C17.5523 6 18 6.44772 18 7H20ZM6 7C6 6.44772 6.44772 6 7 6V4C5.34315 4 4 5.34315 4 7H6ZM8.29289 14.7071L10.2929 16.7071L11.7071 15.2929L9.70711 13.2929L8.29289 14.7071ZM11.7071 16.7071L15.7071 12.7071L14.2929 11.2929L10.2929 15.2929L11.7071 16.7071ZM11 4H13V2H11V4ZM13 6H11V8H13V6ZM11 6C10.4477 6 10 5.55228 10 5H8C8 6.65685 9.34315 8 11 8V6ZM14 5C14 5.55228 13.5523 6 13 6V8C14.6569 8 16 6.65685 16 5H14ZM13 4C13.5523 4 14 4.44772 14 5H16C16 3.34315 14.6569 2 13 2V4ZM11 2C9.34315 2 8 3.34315 8 5H10C10 4.44772 10.4477 4 11 4V2Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className="ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Reduce Cognitive Load & Prevent Errors
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    Offer targeted recommendations, answer specific queries, and
                    interact in a manner that reflects your brand's unique
                    personality.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="bg-dotted dotted2"></div> */}

            <div className="w-full lg:w-1/2 ">
              <div className="mx-auto ">
                <div className="max-w-sm flex-none m-auto sm:max-w-md lg:max-w-2xl">
                  <img
                    src={customerassist}
                    alt="Customer Assistant screenshot"
                    className="mx-auto w-full h-auto rounded-md "
                    data-aos="fade-left"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block items-center sm:flex-col md:flex-row justify-center  px-4 sm:px-12 py-0 lg:flex sm:py-12">
            <div className="w-4/5 m-auto lg:m-0 lg:w-1/2">
              <div className="mx-auto flex justify-center md:block">
                <div className="max-w-sm hidden sm:block  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                  <div className="boxBack"></div>

                  <img
                    src={employeeassist}
                    alt="Employee Assistant screenshot"
                    className="mx-auto  w-full h-auto rounded-md  max-h-[680px]"
                    data-aos="fade-right"
                    style={{ maxWidth: "343.2px" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col justify-center mt-0 sm:mt-10 pl-0 lg:mt-0 lg:w-1/2 lg:pl-10">
              <h1 className="text-3xl sm:text-4xl  leading-9 font-extrabold tracking-tight font-inter text-gray-900 mt-10 text-left lg:text-left sm:mt-0 ">
                Rapid Customer Query Response
              </h1>

              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-2 text-left">
                Customer questions? Non-english speaking employees? Get answers
                fast. From menu details to dietary requirements, Vistry's
                Employee Assistant is equipped to provide rapid, accurate
                information in multiple languages.
              </p>
              <div className="max-w-sm  mt-5 block sm:hidden  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                <img
                  src={employeeassist}
                  alt="Employee Assistant screenshot"
                  className="mx-auto  w-full h-auto rounded-md  max-h-[680px] "
                  data-aos="fade-right"
                />
              </div>

              <div className="flex  mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Enhance Guest Satisfaction
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    Guests appreciate immediate, accurate answers, increasing
                    their overall satisfaction with your service.
                  </p>
                </div>
              </div>

              <div className="flex mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 6V12H16.5M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Save Labor & Time
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    Quick response to multi-lingual inquiries keeps service
                    flowing smoothly, reducing wait times.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative  block items-center flex-col md:flex-row justify-center px-4 sm:px-12 pt-20 pb-10  sm:pt-24 sm:pb-12 lg:flex">
            <div className="w-full flex justify-center pr-0 flex-col lg:w-1/2 sm:pr-6">
              <h1 className="text-3xl sm:text-4xl leading-9 font-extrabold tracking-tight font-inter text-gray-900 text-left">
                Easy Employee Reporting & Feedback
              </h1>
              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-3 text-left">
                Encourage a feedback-rich environment with Vistry's Employee
                Assistant. Employees can effortlessly report issues or offer
                suggestions. Transparent and straightforward, it's a valuable
                tool for continuous operational improvement.
              </p>
              <div className="w-full mt-4 sm:mt-8 lg:w-1/2 block sm:hidden">
                <div className="mx-auto ">
                  <div className="max-w-sm flex-none m-auto sm:max-w-md lg:max-w-2xl">
                    <img
                      src={Voice}
                      alt="Customer Assistant screenshot"
                      className="mx-auto w-full h-auto rounded-md "
                      data-aos="fade-left"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-5 sm:mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7 8H17M7 12H11M12 20L8 16H5C3.89543 16 3 15.1046 3 14V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V14C21 15.1046 20.1046 16 19 16H16L12 20Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Encourage Employee Feedback
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    A simple process for reporting ensures more employees
                    contribute valuable insights.
                  </p>
                </div>
              </div>
              <div className="flex gap-2 sm:gap-4 mt-10 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M8.68387 13.3419C8.88616 12.9381 9 12.4824 9 12C9 11.5176 8.88616 11.0619 8.68387 10.6581M8.68387 13.3419C8.19134 14.3251 7.17449 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.17449 9 8.19134 9.67492 8.68387 10.6581M8.68387 13.3419L15.3161 16.6581M8.68387 10.6581L15.3161 7.34193M15.3161 7.34193C15.8087 8.32508 16.8255 9 18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 6.48237 15.1138 6.93815 15.3161 7.34193ZM15.3161 16.6581C15.1138 17.0619 15 17.5176 15 18C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.8255 15 15.8087 15.6749 15.3161 16.6581Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className="ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Improve Brand-wide Operations
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    Regular feedback enables continuous adjustments, enhancing
                    the efficiency and effectiveness of daily operations, across
                    all locations.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="bg-dotted dotted2"></div> */}

            <div className="w-full lg:w-1/2 hidden sm:block">
              <div className="mx-auto ">
                <div className="max-w-sm flex-none m-auto sm:max-w-md lg:max-w-2xl">
                  <img
                    src={Voice}
                    alt="Customer Assistant screenshot"
                    className="mx-auto w-full h-auto rounded-md "
                    data-aos="fade-left"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="block items-center sm:flex-col md:flex-row justify-center  px-4 sm:px-12 py-0 lg:flex sm:py-12">
            <div className="w-4/5 m-auto lg:m-0 lg:w-1/2">
              <div className="mx-auto flex justify-center md:block">
                <div className="max-w-sm hidden sm:block  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                  <div className="boxBack"></div>

                  <img
                    src={Group2}
                    alt="Employee Assistant screenshot"
                    className="mx-auto  w-full h-auto rounded-md  max-h-[680px]"
                    data-aos="fade-right"
                    style={{ maxWidth: "343.2px" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col justify-center mt-0 sm:mt-10 pl-0 lg:mt-0 lg:w-1/2 lg:pl-10 pb-20 sm:pb-0 ">
              <h1 className="text-3xl sm:text-4xl  leading-9 font-extrabold tracking-tight font-inter text-gray-900 mt-10 text-left lg:text-left sm:mt-0 ">
                Streamlined Opening & Closing Processes
              </h1>

              <p className="text-lg leading-7 font-normal font-inter text-gray-500 mt-2 text-left">
                Opening and closing have never been this smooth. Vistry's
                Employee Assistant provides step-by-step guidance for
                consistent, efficient procedures. Ensure everything is in place,
                whether starting the day or shutting down, with this intuitive
                tool
              </p>
              <div className="max-w-sm  mt-5 block sm:hidden  w-full flex-none sm:max-w-md m-auto lg:m-0 lg:max-w-lg">
                <img
                  src={Group2}
                  alt="Employee Assistant screenshot"
                  className="mx-auto  w-full h-auto rounded-md  max-h-[680px] "
                  data-aos="fade-right"
                />
              </div>
              <div className="flex  mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5M12 12H15M12 16H15M9 12H9.01M9 16H9.01"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Boost Operational Consistency
                  </p>
                  <p className="text-base  leading-7 font-normal font-inter text-gray-500 mt-2">
                    Ensuring every opening and closing follows the exact
                    procedures maintains consistency across shifts.
                  </p>
                </div>
              </div>

              <div className="flex mt-10 gap-2 sm:gap-4 items-start ">
                <div className="svgAdjust">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9.99991 10H5.23598C3.74922 10 2.78222 11.5646 3.44712 12.8944L6.94712 19.8944C7.28591 20.572 7.97843 21 8.73598 21H12.7537C12.9172 21 13.0801 20.9799 13.2388 20.9403L16.9999 20M9.99991 10V5C9.99991 3.89543 10.8953 3 11.9999 3H12.0954C12.5949 3 12.9999 3.40497 12.9999 3.90453C12.9999 4.61883 13.2113 5.31715 13.6076 5.91149L16.9999 11V20M9.99991 10H11.9999M16.9999 20H18.9999C20.1045 20 20.9999 19.1046 20.9999 18V12C20.9999 10.8954 20.1045 10 18.9999 10H16.4999"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className=" ml-2">
                  <p className="text-lg leading-6 font-medium font-inter text-gray-900">
                    Increase Team Efficiency
                  </p>
                  <p className="text-base leading-7 font-normal font-inter text-gray-500 mt-2">
                    Step-by-step guidance saves time, getting the restaurant
                    ready faster for service or closure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAssist;
